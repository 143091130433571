<template>
    <div>
        <!-- 内容 -->
        <transition name="moveCartoon" appear>
            <router-view :key="$route.fullPath"/>
        </transition>
    </div>
</template>
<script>
import {getNewSystemNotice} from '@/api/im'
import {getToken} from '@/utils/cookieUtil'

export default {
    data() {
        return {}
    },
    created() {
        if (this.$store.state.userInfo) {
            if (getToken()) {
                getNewSystemNotice().then(res => {
                    this.$store.commit("setSystemNotice", res.data)
                });
            }
        }
    }
}
</script>
<style scoped>
/* 类名要对应回 name 的属性值 */
.moveCartoon-enter-active {
    animation: move .2s;
}

.moveCartoon-leave-active {
    animation: move .2s reverse;
}

@keyframes move {
    from {
        transform: translateX(-100%);
    }

    to {
        transform: translate(0);
    }
}
</style>