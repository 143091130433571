import axios from 'axios'
import store from '@/store'
import {getToken, removeToken} from '@/utils/cookieUtil'

const service = axios.create({
    baseURL: process.env.VUE_APP_BASE_API,
    timeout: 36000
})

service.interceptors.request.use(
    config => {
        let token = getToken()
        if (token != null) {
            config.headers.Authorization = token
        }
        return config
    },
    error => {
        console.log(error)
        return Promise.reject(error)
    }
)

service.interceptors.response.use(
    response => {
        const res = response.data
        if (res.code !== 200) {
            if (res.code == 401) {
                removeToken()
                sessionStorage.removeItem("user")
                store.state.userInfo = null
                store.state.loginFlag = true
            }
            return Promise.reject(new Error(res.message || 'Error'))
        } else {
            return res
        }
    },
    error => {
        return Promise.reject(error)
    }
)

export default service